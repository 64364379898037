<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  import { getUsers, createUser } from '@/api/Users.js'
  import { User } from "@/models/User";

  /**
   * Basic-table component
   */
  export default {
    page: {
      title: "Usuários",
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader },
    data() {
      return {
        title: "Usuários",
        items: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Usuários",
            active: true,
          },
        ],
        list:[],
        forms: {
          user: new User()
        },
        params: {
          page: 1,
          limit: 50
        },
        ui:{
          forms: {
              user: {
                disabled: false
              }
          },
          pagination:{
            current_page: 1,
            last_page: 1,
            per_page: 20,
            total: 0
          }
        }
      };
    },
    computed: {
      hasItems: function () {
        return this.list.length > 0
      }
    },
    created () {
      this.fetchData();
    },
    methods: {
      fetchData() {
        let vm = this
        getUsers(this.params).then(response => {
          vm.params.page = response.current_page
          vm.params.limit = response.per_page

          vm.ui.pagination.current_page = response.current_page
          vm.ui.pagination.last_page = response.last_page
          vm.ui.pagination.per_page = parseInt(response.per_page)
          vm.ui.pagination.total = response.total

          vm.list = response.data.map(item => {
            return new User(item)
          })
        })
      },
      createUser(){
        let vm = this

        if(!this.ui.forms.user.disabled){
          this.ui.forms.user.disabled = true

          this.$awn.async(
            createUser({name: vm.forms.user.name, email: vm.forms.user.email}),
            function (response) {
              vm.list.unshift(new User(response))
              vm.$awn.success('Usuário criado com sucesso.')
              vm.ui.forms.user.disabled = false
            },
            function () {
              vm.ui.forms.user.disabled = false
              vm.$awn.warning('Ocorreu um erro ao excutar a operação. Favor verificar os dados inseridos e tentar novamente.')
            }
          )
        }
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
      handleOk(bvModalEvt){
        bvModalEvt.preventDefault()

        this.$refs.observer.validate().then(result => {
          if(result){
            this.createUser()
            this.$bvModal.hide('modal-user')
          }
        });
      }
    },
    watch: {
      "ui.pagination.current_page": function (value) {
        this.params.page = value
        this.fetchData()
      }
    }
  };
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped table-hover mb-0">
                <thead>
                <tr>
                  <th class="min">#</th>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Data</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!hasItems">
                  <td colspan="100%" class="text-center">
                    <p class="py-4 mb-0">Nenhum resultado encontrado.</p>
                  </td>
                </tr>
                <tr v-else v-for="item in list" v-bind:key="item.id" :class="{ 'unreaded': !item.readed }">
                  <th scope="row">
                    {{ item.id }}
                  </th>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.email }}
                  </td>
                  <td class="min">{{ $d(item.created_at, 'long') }}</td>
                </tr>
                </tbody>
              </table>
  
              <b-pagination
                :total-rows="ui.pagination.total"
                :per-page="ui.pagination.per_page"
                v-model="ui.pagination.current_page"
                prev-text="Anterior"
                next-text="Próxima"
                align="center"
                class="mt-3 mb-0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    
    <b-button
      variant="primary"
      size="lg"
      class="btn-rounded-fixed"
      pill
      @click="$bvModal.show('modal-user')">
      <i class="bx bx-plus"></i>
    </b-button>
  
    <b-modal
      id="modal-user"
      centered
      title="Adicionar Usuário"
      title-class="font-18"
      :cancel-title="'Cancelar'"
      :ok-title="'Salvar'"
      @ok="handleOk"
    >
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form
          @submit.stop.prevent="handleSubmit(createUser)"
          class="form-horizontal"
        >
          <validation-provider
            name="Name"
            :rules="{ required: true }"
            v-slot="name"
          >
            <b-form-group
              label-cols-sm="3"
              label-cols-lg="3"
              label="Nome"
              label-for="text"
            >
              <b-form-input
                for="text"
                v-model="forms.user.name"
                id="input-user-name"
                name="name"
                :state="getValidationState(name)"
              />
              <b-form-invalid-feedback>{{ name.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
  
          <validation-provider
            name="E-mail"
            :rules="{ required: true, email: true }"
            v-slot="email"
          >
            <b-form-group
              label-cols-sm="3"
              label-cols-lg="3"
              label="E-mail"
              label-for="email"
            >
              <b-form-input
                for="email"
                v-model="forms.user.email"
                id="input-user-name"
                name="email"
                :state="getValidationState(email)"
              />
              <b-form-invalid-feedback>{{ email.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
  
          <template v-slot:modal-footer>
            <b-button size="sm" variant="success" type="submit">
              Salvar
            </b-button>
            <b-button size="sm" variant="danger" @click="$bvModal.hide('modal-user')">
              Cancelar
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </Layout>
</template>

<style>
  .table .unreaded td{
    font-weight: 500 !important;
  }

  .table .unreaded th{
    font-weight: 500 !important;
  }
</style>
